<template>
  <a-modal
    title="添加帮教"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @cancel="cancelForm"
    @ok="saveDataApi"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="帮扶人员姓名"
        :validateStatus="checkParams.visitorNameStatus"
        :help="checkParams.visitorNameStatusMsg"
      >
        <a-input
          style="width: 300px;"
          placeholder="请输入帮扶人员姓名"
          v-decorator="['userName', {initialValue: queryParam.userName, rules: [{required: true, validator: verifyVisitorName, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="帮扶人员电话"
        :validateStatus="checkParams.telephoneStatus"
        :help="checkParams.telephoneStatusMsg"
      >
        <a-input
          style="width: 300px;"
          placeholder="请输入帮扶人员电话"
          :maxLength="11"
          v-decorator="['telephone', {initialValue: queryParam.telephone, rules: [{required: true, validator: verifyTelephone, validateTrigger: 'change'}]}]"
        />
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-textarea :rows="3" v-model="queryParam.remark" placeholder="备注" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { addFocusHelp } from '@/api/control'

export default {
  name: 'AddHelp',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      queryParam: {
        id: '',
        keyId: 0,
        controlKeyId: 0,
        telephone: '',
        userName: '',
        remark: ''
      },
      codeInfo: {
        codeUrl: ''
      },
      checkParams: {
        telephoneStatus: 'success',
        telephoneStatusMsg: null,
        visitorNameStatus: 'success',
        visitorNameStatusMsg: null
      }
    }
  },
  created () {
  },
  methods: {
    ininData (keyId) {
      console.log('ininData', keyId)
      this.queryParam.controlKeyId = keyId
      this.queryParam.telephone = ''
      this.queryParam.userName = ''
      this.queryParam.id = ''
      this.queryParam.keyId = 0
      this.queryParam.remark = ''
      this.checkParams.telephoneStatus = 'success'
      this.checkParams.telephoneStatusMsg = null
      this.checkParams.visitorNameStatus = 'success'
      this.checkParams.visitorNameStatusMsg = null
      this.visible = true
    },
    cancelForm () {
      this.visible = false
    },
    verifyTelephone (rule, value, callback) {
      const _this = this
      _this.queryParam.telephone = value.trim()
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.queryParam.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        callback(new Error('请输入正确的手机号码'))
      }
    },
    verifyVisitorName (rule, value, callback) {
      const _this = this
      _this.queryParam.userName = value.trim()
      if (_this.queryParam.userName !== '') {
        _this.checkParams.visitorNameStatus = 'success'
        _this.checkParams.visitorNameStatusMsg = null
      } else {
        _this.checkParams.visitorNameStatus = 'error'
        _this.checkParams.visitorNameStatusMsg = '请输入帮扶人员姓名'
        callback(new Error('请输入帮扶人员姓名'))
      }
    },
    saveDataApi () {
      const _this = this
      if (_this.queryParam.userName !== '') {
        _this.checkParams.visitorNameStatus = 'success'
        _this.checkParams.visitorNameStatusMsg = null
      } else {
        _this.checkParams.visitorNameStatus = 'error'
        _this.checkParams.visitorNameStatusMsg = '请输入帮扶人员姓名'
        return false
      }
      const mobileReg = /^1[0-9]\d{9}$/
      if (mobileReg.test(_this.queryParam.telephone)) {
        _this.checkParams.telephoneStatus = 'success'
        _this.checkParams.telephoneStatusMsg = null
      } else {
        _this.checkParams.telephoneStatus = 'error'
        _this.checkParams.telephoneStatusMsg = '请输入正确的手机号码'
        return false
      }
      _this.confirmLoading = true
      addFocusHelp(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.cancelForm()
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.confirmLoading = false
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
