<template>
  <a-modal
    title="添加/编辑监控"
    :width="800"
    :destroyOnClose="true"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @cancel="cancelForm"
    @ok="saveDataApi"
  >
    <a-form :form="form">
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="关注人"
        :required="true"
        :validateStatus="checkParams.reporterIdStatus"
        :help="checkParams.reporterIdStatusMsg"
      >
        <a-select
          :value="queryParam.focusKeyId"
          :disabled="queryParam.keyId > 0 || idSet"
          allow-clear
          show-search
          placeholder="请输入姓名/手机号关键字、ID"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          :options="searchMember"
          @search="handleMemberSearch"
          @change="handleMemberChange"
        >
          <template v-if="fetching" #notFoundContent>
            <div style="text-align: center" v-if="isMemberNull === false">
              <a-spin size="small" />
            </div>
            <div v-else>
              <div style="text-align: center">{{ notFoundContent }}</div>
            </div>
          </template>
        </a-select>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="是否有盯防"
      >
        <a-radio-group v-model="queryParam.isHaveDefence">
          <a-radio :value="1">有</a-radio>
          <a-radio :value="0">无</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="是否累犯"
      >
        <a-radio-group v-model="queryParam.isRecidivism">
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        label="备注"
      >
        <a-textarea :rows="3" v-model="queryParam.remark" placeholder="备注" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { seachFocus } from '@/api/focus'
import { checkFocusControl, saveFocusControl } from '@/api/control'

export default {
  name: 'AddControl',
  components: {
  },
  props: {
    statusList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'DealMissionFrom' }),
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      loading: false,
      visible: false,
      progress: 0,
      confirmLoading: false,
      queryParam: {
        id: '',
        keyId: 0,
        areaCode: 0,
        communityId: '',
        focusId: '',
        focusKeyId: 0,
        residentId: '',
        isHaveDefence: 0,
        isRecidivism: 0,
        remark: ''
      },
      codeInfo: {
        codeUrl: ''
      },
      checkParams: {
        reporterIdStatus: 'success',
        reporterIdStatusMsg: null
      },
      searchMember: [],
      memberData: [],
      searchTitle: '',
      timeout: null,
      fetching: false,
      isMemberNull: false,
      notFoundContent: '未搜到关注人',
      idSet: false
    }
  },
  created () {
  },
  methods: {
    ininData (data) {
      console.log('ininData', data)
      this.queryParam.areaCode = data.areaCode
      this.queryParam.communityId = data.communityId
      this.queryParam.id = data.id
      this.queryParam.keyId = data.keyId
      this.queryParam.id = data.id
      this.queryParam.focusId = data.focusId
      this.queryParam.focusKeyId = data.focusKeyId
      this.queryParam.residentId = data.residentId
      this.queryParam.isHaveDefence = data.isHaveDefence
      this.queryParam.isRecidivism = data.isRecidivism
      this.queryParam.remark = data.remark
      this.checkParams.reporterIdStatus = 'success'
      this.checkParams.reporterIdStatusMsg = null
      this.idSet = false
      this.searchMember = []
      if (this.queryParam.focusKeyId > 0) {
        this.searchTitle = this.queryParam.focusKeyId
        this.searchAllMember()
      }
      this.visible = true
    },
    checkControl (focusKeyId) {
      const _this = this
      _this.checkParams.reporterIdStatus = 'success'
      _this.checkParams.reporterIdStatusMsg = null
      _this.queryParam.focusKeyId = focusKeyId
      _this.searchMember = []
      _this.searchTitle = focusKeyId
      _this.searchAllMember()
      checkFocusControl({ focusKeyId: focusKeyId, keyId: 0 }).then((res) => {
        if (res.errorCode === 0) {
          if (res.result.data) {
            _this.queryParam = res.result.data
          } else {
            _this.queryParam.isHaveDefence = 0
            _this.queryParam.isRecidivism = 0
            _this.queryParam.remark = ''
          }
          _this.visible = true
          _this.idSet = true
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    cancelForm () {
      this.visible = false
    },
    saveDataApi () {
      const _this = this
      if (this.queryParam.focusKeyId === '' || this.queryParam.focusKeyId === undefined || this.queryParam.focusKeyId <= 0) {
        this.checkParams.reporterIdStatus = 'error'
        this.checkParams.reporterIdStatusMsg = '请选择关注人'
        return false
      } else {
        this.checkParams.reporterIdStatus = 'success'
        this.checkParams.reporterIdStatusMsg = null
      }
      _this.confirmLoading = true
      saveFocusControl(_this.queryParam).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$emit('ok', 1)
          _this.cancelForm()
        } else if (res.errorCode === 96000001) {
          _this.checkParams.reporterIdStatus = 'error'
          _this.checkParams.reporterIdStatusMsg = res.errorMsg
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.confirmLoading = false
      }).catch((err) => {
        _this.confirmLoading = false
        console.log(err)
      })
    },
    handleMemberSearch (e) {
      const _this = this
      _this.searchTitle = e.trim()
      if (_this.timeout) {
        clearTimeout(_this.timeout)
        _this.timeout = null
      }
      _this.timeout = setTimeout(function () {
        _this.searchAllMember()
      }, 500)
    },
    searchAllMember () {
      const _this = this
      if (_this.searchTitle === '') {
        _this.searchMember = []
        _this.fetching = false
        _this.isMemberNull = false
        return false
      }

      _this.fetching = true
      _this.isMemberNull = false

      var searchParams = {
        title: _this.searchTitle
      }
      seachFocus(searchParams)
        .then((res) => {
          console.log('searchCourseApi', res)
          _this.searchMember = res.result.selectList
          _this.memberData = res.result.userData
          if (_this.searchMember.length > 0) {
            _this.fetching = false
            _this.isMemberNull = false
          } else {
            _this.fetching = true
            _this.isMemberNull = true
          }
        })
        .catch((err) => {
          console.log(err)
          _this.fetching = false
          _this.isMemberNull = false
        })
    },
    handleMemberChange (e) {
      console.log('handleMemberChange', e)
      if (e === '' || e === undefined || e <= 0) {
        this.queryParam.focusKeyId = undefined
        this.searchMember = []
        this.checkParams.reporterIdStatus = 'error'
        this.checkParams.reporterIdStatusMsg = '请选择关注人'
      } else {
        this.queryParam.focusKeyId = e
        this.checkParams.reporterIdStatus = 'success'
        this.checkParams.reporterIdStatusMsg = null
      }
    }
  }
}
</script>

<style lang="less" scoped>
</style>
