<template>
  <a-drawer
    :width="900"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 800px;">
        <a-divider>帮教记录</a-divider>
        <s-table
          ref="table"
          size="default"
          rowKey="keyId"
          :columns="columns"
          :data="loadData"
        >
          <span slot="action" slot-scope="text, record">
            <a-popconfirm title="确认要删除此记录吗？" @confirm="delHelpApi(record.keyId)">
              <a>删除</a>
            </a-popconfirm>
          </span>
        </s-table>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { delFocusHelp, focusHelpList } from '@/api/control'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId'
  },
  {
    title: '帮扶人员姓名',
    dataIndex: 'userName'
  },
  {
    title: '帮扶人员电话',
    dataIndex: 'telephone'
  },
  {
    title: '备注',
    dataIndex: 'remark'
  },
  {
    title: '录入时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '80px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  name: 'SeeHelp',
  components: {
    STable,
    Ellipsis
  },
  props: {
  },
  data () {
    this.columns = columns
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {},
      queryParam: {
        controlKeyId: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return focusHelpList(requestParameters)
          .then(res => {
            return res.result
          })
      }
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    initInfo (controlKeyId) {
      const _this = this
      _this.seeInfoVisible = true
      _this.queryParam.controlKeyId = controlKeyId
    },
    delHelpApi (id) {
      const _this = this
      delFocusHelp({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
