<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>管控信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="关注人">
                <div style="width: 500px;">
                  {{ formData.realName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="关注人手机">
                <div style="width: 500px;">
                  {{ formData.telephone }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="区域">
                <div style="width: 500px;">
                  {{ formData.areaCodeName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="所属社区">
                <div style="width: 500px;">
                  {{ formData.communityName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="状态">
                <a-tag color="#87d068" v-if="formData.status === 0">正在管控</a-tag>
                <a-tag color="#595959" v-else>已解除管控</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="解除管控时间">
                <div style="width: 500px;">
                  {{ formData.freeTimeDate }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否有盯防">
                <div style="width: 500px;">
                  {{ formData.isHaveDefenceStr }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="是否累犯">
                <div style="width: 500px;">
                  {{ formData.isRecidivismStr }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="备注信息">
                <div style="width: 500px;">
                  {{ formData.remark }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="录入时间">
                <div style="width: 500px;">
                  {{ formData.createTime }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
    </a-card>
  </a-drawer>
</template>

<script>
export default {
  name: 'SeeControl',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {}
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    initInfo (data) {
      const _this = this
      _this.seeInfoVisible = true
      _this.formData = data
    }
  }
}
</script>

<style lang="less" scoped>
</style>
