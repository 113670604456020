import request from '@/utils/request'
const baseURL = '/baseapi'
const focusApi = {
  controlList: '/admin/control/list',
  FocusControlInfo: '/admin/control/focusControlInfo',
  SaveFocusControl: '/admin/control/saveFocusControl',
  FocusControlById: '/admin/control/focusControlById',
  DelControl: '/admin/control/delControl',
  CheckFocusControl: '/admin/control/checkFocusControl',
  HelpList: '/admin/control/helpList',
  AddFocusHelp: '/admin/control/addFocusHelp',
  DelFocusHelp: '/admin/control/delFocusHelp'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function focusHelpList (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.HelpList,
    method: 'post',
    data: parameter
  })
}

export function addFocusHelp (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.AddFocusHelp,
    method: 'post',
    data: parameter
  })
}

export function delFocusHelp (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.DelFocusHelp,
    method: 'post',
    data: parameter
  })
}

export function controlList (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.controlList,
    method: 'post',
    data: parameter
  })
}

export function checkFocusControl (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.CheckFocusControl,
    method: 'post',
    data: parameter
  })
}

export function focusControlInfo (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.FocusControlInfo,
    method: 'post',
    data: parameter
  })
}

export function delControl (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.DelControl,
    method: 'post',
    data: parameter
  })
}

export function focusControlById (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.FocusControlById,
    method: 'post',
    data: parameter
  })
}

export function saveFocusControl (parameter) {
  return request({
    baseURL: baseURL,
    url: focusApi.SaveFocusControl,
    method: 'post',
    data: parameter
  })
}
